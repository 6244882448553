import * as React from 'react';



const SvgKupStarterPackCovid = (props) => (
    <svg viewBox="0 0 156.4 156.4" {...props}>
        <circle cx={78.2} cy={78.2} r={78.2} fill="#9f9f9f" />
        <path fill="none" d="M18.77 88.82h118.85V99.7H18.77z" />
        <text
            transform="translate(57.42 93.4)"
            fill="#fff"
            fontSize={10}
            fontFamily="OpenSans-Bold,Open Sans"
            fontWeight={700}
        >
            {'Wysy\u0142ka '}
            <tspan x={-17.36} y={12}>
                {'Starter pack\xF3w'}
            </tspan>
            <tspan x={-17.5} y={24}>
                {'wstrzymana do'}
            </tspan>
            <tspan x={-6.78} y={36}>
                {'odwo\u0142ania.'}
            </tspan>
        </text>
        <path
            d="M94.39 56.15l4.07-17.87a2.07 2.07 0 00-2-2.52H62.7l-.79-3.85a2.06 2.06 0 00-2-1.65h-8.83A2.06 2.06 0 0049 32.32v1.38a2.06 2.06 0 002.06 2.06h6l6 29.5a4.82 4.82 0 105.76.74h18a4.81 4.81 0 105.47-.9l.54-2.1a2.06 2.06 0 00-2-2.52H67.76l-.56-2.75h25.18a2 2 0 002.01-1.58z"
            fill="#fff"
        />
        <path
            d="M113.22 28.51a4 4 0 00-5.47-1.17L77.44 46.85 44.27 68.2a3.84 3.84 0 00-1.1 5.39 4 4 0 005.47 1.17L89.76 48.3h0l22.37-14.4a3.83 3.83 0 001.09-5.39z"
            stroke="#9f9f9f"
            strokeMiterlimit={10}
            strokeWidth={2}
            fill="#fff"
        />
    </svg>
);

export default SvgKupStarterPackCovid;
