import React from 'react';

class SvgKupStarterPack extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg
                version={1.1}
                id="kup-starterpack"
                x={0}
                y={0}
                viewBox="0 0 156.396 156.396"
                xmlSpace="preserve"
                {...props}
            >
                <circle  cx="78.198" cy="78.198" r="78.198" className="st0" />
                <rect x="20.772" y="94.662" fill="none" width="118.854" height="10.88"/>
                <path fill="#FFFFFF" d="M99.21,73.887l4.643-20.423c0.336-1.475-0.785-2.879-2.297-2.879H62.99l-0.901-4.4
	c-0.224-1.096-1.189-1.884-2.308-1.884H49.713c-1.301,0-2.355,1.055-2.355,2.356v1.571c0,1.302,1.054,2.356,2.355,2.356h6.862
	l6.897,33.72c-1.65,0.949-2.763,2.729-2.763,4.77c0,3.036,2.462,5.499,5.5,5.499c3.036,0,5.498-2.463,5.498-5.499
	c0-1.54-0.633-2.931-1.652-3.928h20.584c-1.018,0.997-1.652,2.388-1.652,3.928c0,3.036,2.463,5.499,5.5,5.499
	c3.035,0,5.498-2.463,5.498-5.499c0-2.178-1.266-4.06-3.1-4.95l0.541-2.383c0.336-1.475-0.787-2.879-2.299-2.879H68.773
	l-0.643-3.142h28.784C98.014,75.721,98.968,74.96,99.21,73.887z"/>
                <g>
                    <path fill="#FFFFFF" d="M37.945,101.719h1.743v3.441h0.035c0.173-0.301,0.358-0.578,0.531-0.855l1.767-2.586h2.16l-2.576,3.313
		l2.715,4.47h-2.057l-1.905-3.36l-0.67,0.819v2.541h-1.743V101.719z"/>
                    <path fill="#FFFFFF" d="M50.087,107.69c0,0.739,0.022,1.34,0.045,1.813h-1.523l-0.08-0.796h-0.036
		c-0.219,0.346-0.75,0.923-1.766,0.923c-1.144,0-1.987-0.717-1.987-2.46v-3.313h1.767v3.037c0,0.82,0.266,1.316,0.879,1.316
		c0.485,0,0.762-0.335,0.877-0.613c0.046-0.104,0.058-0.242,0.058-0.38v-3.36h1.768V107.69z"/>
                    <path fill="#FFFFFF" d="M51.498,105.75c0-0.739-0.023-1.375-0.046-1.894h1.524l0.081,0.784h0.023
		c0.416-0.6,1.063-0.912,1.883-0.912c1.235,0,2.343,1.074,2.343,2.875c0,2.056-1.304,3.026-2.563,3.026
		c-0.682,0-1.214-0.276-1.468-0.646h-0.022v2.806h-1.756V105.75z M53.254,107.078c0,0.139,0.012,0.265,0.034,0.369
		c0.116,0.474,0.521,0.831,1.028,0.831c0.762,0,1.213-0.635,1.213-1.594c0-0.9-0.404-1.581-1.19-1.581
		c-0.496,0-0.935,0.369-1.051,0.89c-0.022,0.092-0.034,0.206-0.034,0.311V107.078z"/>
                    <path fill="#FFFFFF" d="M60.857,107.69c0.475,0.241,1.202,0.484,1.952,0.484c0.81,0,1.236-0.335,1.236-0.843
		c0-0.485-0.37-0.762-1.306-1.097c-1.293-0.451-2.137-1.167-2.137-2.298c0-1.329,1.11-2.346,2.945-2.346
		c0.879,0,1.524,0.186,1.986,0.394l-0.393,1.42c-0.312-0.149-0.865-0.369-1.628-0.369s-1.132,0.347-1.132,0.751
		c0,0.496,0.438,0.715,1.442,1.096c1.375,0.509,2.022,1.225,2.022,2.322c0,1.305-1.005,2.414-3.142,2.414
		c-0.889,0-1.768-0.231-2.206-0.475L60.857,107.69z"/>
                    <path fill="#FFFFFF" d="M68.888,102.239v1.617h1.258v1.293h-1.258v2.043c0,0.683,0.161,0.994,0.691,0.994
		c0.221,0,0.394-0.023,0.521-0.046l0.012,1.328c-0.231,0.092-0.646,0.15-1.144,0.15c-0.565,0-1.039-0.197-1.316-0.485
		c-0.323-0.335-0.484-0.879-0.484-1.675v-2.31h-0.751v-1.293h0.751v-1.225L68.888,102.239z"/>
                    <path fill="#FFFFFF" d="M74.376,109.503l-0.104-0.566h-0.034c-0.37,0.451-0.947,0.693-1.617,0.693
		c-1.144,0-1.825-0.831-1.825-1.732c0-1.467,1.317-2.171,3.315-2.158v-0.081c0-0.301-0.162-0.729-1.028-0.729
		c-0.577,0-1.189,0.196-1.559,0.428l-0.323-1.133c0.393-0.219,1.166-0.496,2.194-0.496c1.883,0,2.482,1.109,2.482,2.438v1.963
		c0,0.542,0.022,1.063,0.081,1.374H74.376z M74.167,106.835c-0.923-0.011-1.64,0.208-1.64,0.89c0,0.45,0.301,0.67,0.693,0.67
		c0.439,0,0.796-0.289,0.913-0.647c0.022-0.092,0.033-0.195,0.033-0.3V106.835z"/>
                    <path fill="#FFFFFF" d="M77.265,105.715c0-0.832-0.023-1.373-0.046-1.858h1.512l0.059,1.039h0.047
		c0.288-0.82,0.982-1.167,1.523-1.167c0.162,0,0.243,0,0.371,0.023v1.651c-0.128-0.023-0.278-0.046-0.475-0.046
		c-0.646,0-1.085,0.346-1.201,0.89c-0.022,0.114-0.034,0.254-0.034,0.393v2.863h-1.756V105.715z"/>
                    <path fill="#FFFFFF" d="M83.862,102.239v1.617h1.26v1.293h-1.26v2.043c0,0.683,0.162,0.994,0.694,0.994
		c0.218,0,0.391-0.023,0.519-0.046l0.012,1.328c-0.231,0.092-0.647,0.15-1.144,0.15c-0.566,0-1.038-0.197-1.316-0.485
		c-0.323-0.335-0.485-0.879-0.485-1.675v-2.31h-0.75v-1.293h0.75v-1.225L83.862,102.239z"/>
                    <path fill="#FFFFFF" d="M87.433,107.239c0.057,0.729,0.773,1.074,1.593,1.074c0.601,0,1.086-0.081,1.56-0.231l0.231,1.19
		c-0.577,0.23-1.283,0.347-2.045,0.347c-1.916,0-3.015-1.109-3.015-2.876c0-1.433,0.89-3.015,2.854-3.015
		c1.824,0,2.519,1.421,2.519,2.818c0,0.3-0.036,0.565-0.059,0.692H87.433z M89.501,106.038c0-0.427-0.185-1.143-0.993-1.143
		c-0.739,0-1.04,0.669-1.085,1.143H89.501z"/>
                    <path fill="#FFFFFF" d="M92.182,105.715c0-0.832-0.022-1.373-0.045-1.858h1.512l0.059,1.039h0.045
		c0.29-0.82,0.981-1.167,1.525-1.167c0.162,0,0.242,0,0.37,0.023v1.651c-0.128-0.023-0.278-0.046-0.474-0.046
		c-0.647,0-1.085,0.346-1.202,0.89c-0.022,0.114-0.033,0.254-0.033,0.393v2.863h-1.757V105.715z"/>
                    <path fill="#FFFFFF" d="M98.966,101.823c0.544-0.092,1.306-0.162,2.38-0.162c1.085,0,1.859,0.208,2.379,0.624
		c0.496,0.393,0.831,1.039,0.831,1.802s-0.254,1.409-0.717,1.848c-0.6,0.566-1.489,0.82-2.527,0.82c-0.231,0-0.44-0.012-0.602-0.035
		v2.783h-1.744V101.823z M100.71,105.357c0.15,0.034,0.335,0.046,0.589,0.046c0.937,0,1.514-0.474,1.514-1.271
		c0-0.716-0.497-1.144-1.375-1.144c-0.357,0-0.6,0.035-0.728,0.069V105.357z"/>
                    <path fill="#FFFFFF" d="M108.624,109.503l-0.104-0.566h-0.034c-0.37,0.451-0.948,0.693-1.617,0.693
		c-1.144,0-1.824-0.831-1.824-1.732c0-1.467,1.316-2.171,3.313-2.158v-0.081c0-0.301-0.162-0.729-1.026-0.729
		c-0.578,0-1.191,0.196-1.56,0.428l-0.323-1.133c0.393-0.219,1.166-0.496,2.192-0.496c1.883,0,2.484,1.109,2.484,2.438v1.963
		c0,0.542,0.022,1.063,0.081,1.374H108.624z M108.417,106.835c-0.924-0.011-1.641,0.208-1.641,0.89c0,0.45,0.302,0.67,0.692,0.67
		c0.44,0,0.798-0.289,0.912-0.647c0.024-0.092,0.036-0.195,0.036-0.3V106.835z"/>
                    <path fill="#FFFFFF" d="M115.753,109.365c-0.312,0.138-0.9,0.254-1.57,0.254c-1.824,0-2.992-1.109-2.992-2.888
		c0-1.65,1.133-3.003,3.235-3.003c0.461,0,0.971,0.081,1.339,0.22l-0.276,1.306c-0.209-0.093-0.521-0.173-0.981-0.173
		c-0.926,0-1.525,0.658-1.515,1.581c0,1.039,0.694,1.582,1.548,1.582c0.416,0,0.739-0.069,1.005-0.173L115.753,109.365z"/>
                    <path fill="#FFFFFF" d="M118.482,106.235h0.022c0.128-0.231,0.265-0.463,0.404-0.682l1.144-1.697h2.113l-2.021,2.286l2.31,3.36
		h-2.159l-1.363-2.321l-0.449,0.555v1.767h-1.757v-8.198h1.757V106.235z"/>
                </g>
            </svg>
        );
    }
}

export default SvgKupStarterPack;
