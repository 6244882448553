import React from 'react';

const SvgNoButtonsJustGridMainMd = props => (
    <svg
        id="noButtonsJustGridMainMd_svg__Warstwa_1"
        data-name="Warstwa 1"
        viewBox="0 0 853.5 1253.1"
        {...props}
    >
        <defs>
            <style>
                {
                    '.noButtonsJustGridMainMd_svg__cls-1{fill:#fff}.noButtonsJustGridMainMd_svg__cls-2{fill:#b3b3b2}.noButtonsJustGridMainMd_svg__cls-5,.noButtonsJustGridMainMd_svg__cls-6,.noButtonsJustGridMainMd_svg__cls-7{fill:none;stroke-miterlimit:10;opacity:.6;isolation:isolate}.noButtonsJustGridMainMd_svg__cls-5{stroke:#9f9f9f}.noButtonsJustGridMainMd_svg__cls-4{fill:#9f9f9f}.noButtonsJustGridMainMd_svg__cls-5{stroke-width:8.69px}.noButtonsJustGridMainMd_svg__cls-6,.noButtonsJustGridMainMd_svg__cls-7{stroke:#3d3d3d;stroke-width:4.64px}.noButtonsJustGridMainMd_svg__cls-7{stroke-dasharray:16.23 9.27}'
                }
            </style>
        </defs>
        <path
            className="noButtonsJustGridMainMd_svg__cls-1"
            d="M652.95 179.52l-33.38 58.06-66.98.11-33.6-57.88 33.37-58.11 66.98-.12 33.61 57.94z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-2"
            d="M550.68 241.05l-35.52-61.24 35.29-61.42 70.8-.11 35.52 61.24-35.29 61.42zm-27.87-61.24l31.63 54.58 63.16-.11 31.46-54.76-31.63-54.64-63.1.12z"
        />
        <path
            d="M79.94 781a62.37 62.37 0 1088.21 0 62.36 62.36 0 00-88.21 0z"
            style={{
                isolation: 'isolate'
            }}
            strokeWidth={9.39}
            stroke="#9f9f9f"
            fill="none"
            strokeMiterlimit={10}
            opacity={0.6}
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-4"
            d="M677.15 580.58l-33.38 58.06-66.98.12-33.6-57.94 33.37-58.06 66.98-.12 33.61 57.94z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-1"
            d="M581.08 631.34l-29.32-50.52 29.09-50.64 58.4-.12 29.32 50.52-29.08 50.64zm-21.26-50.52l25.26 43.57 50.41-.12 25.09-43.69L635.31 537l-50.41.12zM331.7 1042.43l48.64-84.62 97.64-.17 48.98 84.37-48.65 84.71-97.63.17-48.98-84.46z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-2"
            d="M480.76 952.74l51.78 89.26-51.44 89.53-103.21.17-51.77-89.28 51.44-89.52zm40.63 89.26l-46.12-79.56-92.06.17-45.86 79.81 46.12 79.65 92-.17z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-4"
            d="M686.37 893.08l-22.29-82.55 60.38-60.58 82.66 21.97 22.28 82.55-60.37 60.59-82.66-21.98z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-1"
            d="M727.28 760.51l72.09 19.15 19.47 72-52.63 52.84-72.1-19.15-19.46-72zm64.82 26.42L730 770.4 684.54 816l16.79 62.1 62.15 16.53L808.9 849z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-4"
            d="M186.8 216.26l13.49 49.96-36.54 36.66-50.02-13.3-13.49-49.96 36.54-36.66 50.02 13.3z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-1"
            d="M162 296.49l-43.58-11.59-11.78-43.57 31.85-32 43.63 11.58 11.78 43.57zm-39.22-16l37.61 10 27.48-27.61-10.16-37.58-37.61-10-27.49 27.61z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-5"
            d="M166.44 580.7a16.16 16.16 0 10-16.16-16.17 16.17 16.17 0 0016.16 16.17zM718.92 370.42a16.16 16.16 0 10-16.15-16.17 16.16 16.16 0 0016.15 16.17zM633.06 1026.82a16.16 16.16 0 1022.85 0 16.16 16.16 0 00-22.85 0z"
        />
        <path className="noButtonsJustGridMainMd_svg__cls-6" d="M362.5 0v252.92" />
        <path
            className="noButtonsJustGridMainMd_svg__cls-7"
            d="M681.23 777.33l-116.91-6.3"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-6"
            d="M427.33 880.9l1.32 68.97"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-7"
            d="M343.46 991.89l-156.9-115.06"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-5"
            d="M237.72 1074.78a16.16 16.16 0 1022.85 0 16.15 16.15 0 00-22.84 0z"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-7"
            d="M623.56 244.77l75.27 87.14"
        />
        <path
            className="noButtonsJustGridMainMd_svg__cls-6"
            d="M702.77 377.62l-53.71 136.59"
        />
    </svg>
);

export default SvgNoButtonsJustGridMainMd;
