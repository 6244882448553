import React from 'react';

class SvgSvgNoButtonsJustGridFutureXs extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg viewBox="0 -113 360 1386" {...props}>
                <path
                    opacity={0.6}
                    fill="#3D3D3D"
                    d="M191.503 948.312l-1.879 2.169-45.786-40.549 1.879-2.168zM198.51 141.281l-46.467 39.545-1.852-2.191 46.465-39.545z"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M82.547 519.312l-.24-15.448 3.442-.054.24 15.449zM82.902 542.198l-.24-15.446 3.442-.053.24 15.446zM83.258 565.108l-.237-15.449 3.44-.053.236 15.45zM83.613 587.966l-.241-15.452 3.438-.053.241 15.451zM83.969 610.9l-.238-15.45 3.44-.053.239 15.45z"
                    />
                </g>
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M266.324 170.047l3.234.054-.24 14.518-3.235-.053zM265.964 191.556l3.235.052-.236 14.518-3.236-.053zM265.62 213.045l3.236.052-.233 14.517-3.234-.052zM265.267 234.55l3.235.051-.233 14.517-3.234-.052z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="#3D3D3D"
                    d="M159.094 253.677l52.181 31.624-1.496 2.449-52.179-31.623zM211.344 360.587l-56.846 33.82-1.467-2.465 56.846-33.82zM190.621 497.77l-43.808-25.775 1.476-2.461 43.807 25.773z"
                />
                <path
                    opacity={0.6}
                    fill="#5D5D5D"
                    d="M199.836 818.48l14.541-5.21 1.161 3.241-14.54 5.21zM178.246 826.194l14.547-5.21 1.16 3.239-14.546 5.21zM156.68 833.96l14.547-5.212 1.16 3.237-14.546 5.212zM135.132 841.643l14.543-5.209 1.16 3.24-14.543 5.208z"
                />
                <path
                    opacity={0.6}
                    fill="#3D3D3D"
                    d="M210.953 1047.223l-46.811 22.456-1.095-2.654 46.808-22.455zM86.175 813.064l9.585-57.197 2.828.487-9.585 57.196z"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M69.531 949.732l-1.126-15.406 3.434-.251 1.126 15.406zM71.19 972.504l-1.125-15.405 3.432-.25 1.125 15.404zM72.85 995.27l-1.126-15.408 3.432-.25 1.125 15.407zM74.532 1018.186l-1.126-15.41 3.43-.252 1.127 15.412zM76.192 1040.99l-1.124-15.405 3.433-.25 1.124 15.405z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="#5D5D5D"
                    d="M77.511 1063.868l-1.125-15.409 3.433-.25 1.125 15.408z"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M265.701 619.672l3.221-.25 1.124 14.475-3.221.25zM267.319 641.096l3.225-.253 1.135 14.472-3.225.253zM269.016 662.586l3.224-.252 1.131 14.476-3.224.252zM270.67 683.993l3.225-.254 1.14 14.474-3.225.254z"
                    />
                </g>
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M266.322-81.267l3.234.053-.241 14.519-3.234-.054zM265.964-59.757l3.236.053-.237 14.518-3.235-.053zM265.62-38.266l3.235.052-.232 14.517-3.235-.052zM265.267-16.762l3.234.052-.232 14.517-3.235-.052z"
                    />
                </g>
            </svg>
        );
    }
}

export default SvgSvgNoButtonsJustGridFutureXs;
