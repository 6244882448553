import React from 'react';

const SvgNoButtonsJustGridMainLg = props => (
    <svg
        id="noButtonsJustGridMainLg_svg__Warstwa_1"
        data-name="Warstwa 1"
        viewBox="0 0 1297.68 831.53"
        {...props}
    >
        <defs>
            <style>
                {
                    '.noButtonsJustGridMainLg_svg__cls-1{fill:#9f9f9f}.noButtonsJustGridMainLg_svg__cls-2{fill:#fff}.noButtonsJustGridMainLg_svg__cls-3{fill:#b3b3b2}.noButtonsJustGridMainLg_svg__cls-5,.noButtonsJustGridMainLg_svg__cls-6,.noButtonsJustGridMainLg_svg__cls-7{fill:none;stroke-miterlimit:10;opacity:.6;isolation:isolate}.noButtonsJustGridMainLg_svg__cls-5{stroke:#9f9f9f;stroke-width:8.69px}.noButtonsJustGridMainLg_svg__cls-6,.noButtonsJustGridMainLg_svg__cls-7{stroke:#3d3d3d;stroke-width:4.64px}.noButtonsJustGridMainLg_svg__cls-7{stroke-dasharray:16.23 9.27}'
                }
            </style>
        </defs>
        <path
            className="noButtonsJustGridMainLg_svg__cls-1"
            d="M554.09 660.01l-33.37 58.06-66.98.11-33.61-57.94 33.37-58.05 66.98-.12 33.61 57.94z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-2"
            d="M458 710.77l-29.31-50.53 29.08-50.64 58.41-.11L545.51 660l-29.08 50.64zm-21.26-50.53L462 703.82l50.41-.12 25.11-43.7-25.26-43.57-50.41.12zM213.22 493.35l-33.38 58.05-66.98.12-33.6-57.88 33.37-58.12 66.98-.11 33.61 57.94z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-3"
            d="M111 554.88l-35.57-61.24 35.29-61.42 70.8-.12L217 493.35l-35.28 61.42zm-27.92-61.24l31.64 54.58 63.15-.12 31.47-54.75-31.64-54.64-63.1.12z"
        />
        <path
            d="M1109.93 713.08a62.38 62.38 0 10-62.35-62.41h0a62.36 62.36 0 0062.33 62.41z"
            style={{
                isolation: 'isolate'
            }}
            strokeWidth={9.39}
            stroke="#9f9f9f"
            fill="none"
            strokeMiterlimit={10}
            opacity={0.6}
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-1"
            d="M554.09 660.01l-33.37 58.06-66.98.11-33.61-57.94 33.37-58.05 66.98-.12 33.61 57.94z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-2"
            d="M458 710.77l-29.31-50.53 29.08-50.64 58.41-.11L545.51 660l-29.08 50.64zm-21.26-50.53L462 703.82l50.41-.12 25.11-43.7-25.26-43.57-50.41.12zM1116.77 350.2l-94.24 25.44-69.16-68.91 25.02-94.3 94.3-25.5 69.16 68.91-25.08 94.36z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-3"
            d="M947.94 308.22l26.51-99.73 99.68-26.94 73.1 72.86-26.52 99.73-99.71 26.94zm34.4-91.85l-23.65 88.86 65.21 65 88.87-24 23.71-88.92-65.16-64.92z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-1"
            d="M760.37 205.02l-42.62 74.13-85.53.15-42.91-73.99 42.62-74.13 85.53-.15 42.91 73.99z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-2"
            d="M637.7 269.83l-37.44-64.52 37.14-64.66 74.6-.15 37.42 64.5-37.14 64.66zm-27.15-64.52L642.8 261l64.37-.15 32-55.78L707 149.38l-64.37.15z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-1"
            d="M244.44 216.26l13.49 49.96-36.54 36.66-50.02-13.3-13.49-49.96 36.53-36.66 50.03 13.3z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-2"
            d="M219.68 296.49l-43.63-11.59-11.78-43.57 31.85-32 43.63 11.58 11.78 43.57zm-39.23-16l37.62 10 27.48-27.61-10.17-37.58-37.61-10-27.48 27.61z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-5"
            d="M610.1 487.31a16.16 16.16 0 10-16.1-16.22v.06a16.15 16.15 0 0016.1 16.16zM213.22 726.93a16.16 16.16 0 10-16.15-16.17 16.17 16.17 0 0016.15 16.17zM892.63 148.14A16.16 16.16 0 10876.48 132a16.17 16.17 0 0016.15 16.14z"
        />
        <path className="noButtonsJustGridMainLg_svg__cls-6" d="M420.13 0v252.92" />
        <path
            className="noButtonsJustGridMainLg_svg__cls-7"
            d="M682.16 290.5l78.21 87.12"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-6"
            d="M934.93 396.8l47.83-49.7"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-7"
            d="M1072.7 377.62l29.59 192.31"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-6"
            d="M207.9 524.13l212.2 97.4"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-5"
            d="M1206.09 393.79a16.17 16.17 0 10-16.15-16.19 16.17 16.17 0 0016.15 16.19z"
        />
        <path
            className="noButtonsJustGridMainLg_svg__cls-7"
            d="M146.21 562.49l54.32 116.14"
        />
    </svg>
);

export default SvgNoButtonsJustGridMainLg;
