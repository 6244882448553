import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import classNames from 'classnames';

class Button extends Component {
    static propTypes = {
        className: PropTypes.string,
        link: PropTypes.string.isRequired,
        linkLabel: PropTypes.string.isRequired,
        buttonType: PropTypes.oneOf(['circleButton', 'hexButton']).isRequired,
        children: PropTypes.node,
        isDisabled: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        children: null,
        isDisabled: false,
    };

    render() {
        const {
            className,
            link,
            linkLabel,
            buttonType,
            children,
            isDisabled
        } = this.props;
        return(
            <div className={classNames('oneButton', buttonType, className)}>
                {isDisabled ? children : (
                    <Link aria-label={linkLabel} to={link}>
                        {children}
                    </Link>
                )}
            </div>
        );
    }


}

export default Button;
