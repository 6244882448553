import React from 'react';
import Button from '../button';
import SvgKompetencjeEkonomiczne from '../../../svg/buttonsSeparate/mainPageSeparateButtons/SvgKompetencjeEkonomiczne';
import SvgKompetencjePrzyszlosci from '../../../svg/buttonsSeparate/mainPageSeparateButtons/SvgKompetencjePrzyszlosci';
import SvgPracaPrzyszlosci from '../../../svg/buttonsSeparate/mainPageSeparateButtons/SvgPracaPrzyszlosci';
import constants from '../../../constants/javascriptConstants';
import SvgKupStarterPack from '../../../svg/buttonsSeparate/buyStarterpackButtons/SvgKupStarterPack';
import SvgKupStarterPackCovid from '../../../svg/covid/SvgKupStarterPackCovid';

class MainButtons extends React.Component {
    render() {
        return (
            <>
                <Button
                    className="kompetencjeEkonomiczne"
                    buttonType="hexButton"
                    linkLabel="Kompetencje Ekonomiczne"
                    link="/kompetencje-ekonomiczne/"
                >
                    <SvgKompetencjeEkonomiczne />
                </Button>
                <Button
                    className="kompetencjePrzyszlosci"
                    buttonType="circleButton"
                    linkLabel="Kompetencje Przyszłości"
                    link="/kompetencje-przyszlosci/"
                >
                    <SvgKompetencjePrzyszlosci />
                </Button>
                <Button
                    className="pracaPrzyszlosci"
                    buttonType="hexButton"
                    linkLabel="Praca Przyszłości"
                    link="/praca-przyszlosci/"
                >
                    <SvgPracaPrzyszlosci />
                </Button>
                <Button
                    className="kupStarterPack kupStarterPack--mainPage"
                    link="/kup-starter-pack/"
                    buttonType='circleButton'
                    linkLabel="Kup Starter Pack"
                    isDisabled={constants.isCovid}
                >
                    {constants.isCovid
                        ? <SvgKupStarterPackCovid />
                        : <SvgKupStarterPack />
                    }
                </Button>
            </>
        );
    }
}

export default MainButtons;
