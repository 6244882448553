import React from 'react';

const SvgSvgNoButtonsJustGridEconomicMd = props => (
    <svg
        data-name="Warstwa 2"
        viewBox="0 0 768.85 1151.17"
        {...props}
    >
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="none"
            stroke="#3d3d3d"
            strokeMiterlimit={10}
            strokeWidth={2.872}
            opacity={0.6}
            d="M301.97 900.3l-57 68.3"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="none"
            stroke="#3d3d3d"
            strokeMiterlimit={10}
            strokeWidth={3}
            opacity={0.6}
            d="M266.97 1016.1h203.8"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M240.45 304.852l8.564-9.647 2.169 1.926-8.565 9.646zM227.722 319.16l8.566-9.646 2.168 1.926-8.566 9.645zM215.069 333.482l8.563-9.648 2.168 1.925-8.562 9.648zM202.288 347.872l8.563-9.648 2.169 1.925-8.563 9.648zM189.552 362.128l8.565-9.647 2.168 1.926-8.564 9.646zM499.636 540.278l12.015-6.154 1.368 2.67-12.016 6.154zM481.839 549.548l12.019-6.148 1.366 2.671-12.019 6.148zM464.013 558.508l12.017-6.152 1.367 2.67-12.017 6.152zM446.206 567.754l12.02-6.143 1.366 2.67-12.02 6.144z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="none"
            stroke="#3d3d3d"
            strokeMiterlimit={10}
            strokeWidth={3}
            opacity={0.6}
            d="M189.57 451.5l100.7 85.6M402.37 260.6l107.3 39.6M313.27 0v180.6"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="none"
            stroke="#3d3d3d"
            strokeMiterlimit={10}
            strokeWidth={2.538}
            opacity={0.6}
            d="M594.53 0v223.25"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M601.37 388.5l-2.5-.1.2-11.1h2.5l-.2 11.2zM600.97 405l-2.4-.1.2-11.1h2.5l-.3 11.2zM600.67 421.5l-2.5-.1.2-11.1h2.5l-.2 11.2zM600.27 438l-2.4-.1.2-11.1h2.5l-.3 11.2zM599.97 454.5l-2.5-.1.2-11.1h2.5l-.2 11.2z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="none"
            stroke="#3d3d3d"
            strokeMiterlimit={10}
            strokeWidth={3}
            opacity={0.6}
            d="M317.87 333.8l38.8 160.5M603.77 610.2l14.8 69.4M437.97 818.3l106.5-28.2"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M356.32 649.942l2.888-.275 1.225 12.842-2.887.275zM358.116 668.937l2.887-.276 1.225 12.842-2.887.275zM359.844 688.122l2.886-.276 1.228 12.841-2.887.276zM361.752 707.213l2.887-.276 1.228 12.842-2.887.276zM363.616 726.27l2.887-.276 1.228 12.841-2.887.276zM365.56 745.417l2.887-.276 1.227 12.842-2.887.276zM367.514 764.366l2.887-.276 1.227 12.841-2.887.276z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="none"
            stroke="#3d3d3d"
            strokeMiterlimit={10}
            strokeWidth={3}
            opacity={0.6}
            d="M219.87 669.7l73.5-58.5"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M159.439 807.968l2.887-.275 1.225 12.841-2.887.276zM161.224 827.062l2.887-.276 1.225 12.842-2.887.276zM163.029 846.21l2.887-.275 1.227 12.841-2.887.276zM164.828 865.303l2.887-.276 1.227 12.842-2.886.275zM166.823 884.367l2.887-.276 1.227 12.842-2.887.276zM168.637 903.518l2.886-.276 1.228 12.842-2.887.276zM170.613 922.484l2.886-.276 1.228 12.841-2.887.276zM588.789 863.411l5.764-11.54 2.594 1.295-5.764 11.541zM580.324 880.601l5.772-11.536 2.594 1.297-5.772 11.537zM571.693 897.72l5.764-11.54 2.594 1.295-5.764 11.54zM563.068 915.119l5.77-11.538 2.594 1.297-5.77 11.538zM554.436 931.937l5.764-11.54 2.594 1.295-5.764 11.54zM238.155 1097.76l2.102-1.999 8.886 9.352-2.102 1.997zM251.348 1111.676l2.103-1.997 8.886 9.35-2.102 1.999zM264.586 1125.528l2.102-1.999 8.89 9.348-2.102 1.999zM277.798 1139.413l2.101-1.998 8.89 9.348-2.102 1.998z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="none"
            stroke="#3d3d3d"
            strokeMiterlimit={10}
            strokeWidth={3}
            opacity={0.6}
            d="M426.97 1148.3l73.2-82.3"
        />
    </svg>
);

export default SvgSvgNoButtonsJustGridEconomicMd;
