import React from 'react';
import PropTypes from 'prop-types';
import AnimatedDiv from './AnimatedDiv';
import SkewedDiv from '../SkewedDiv';
import { withWidth } from '../withWidth';
import './Slider.scss';
import Buttons from '../Buttons';
import constants from '../../constants/javascriptConstants';
import { ButtonsComponentHeightContext } from '../buttonsComponentHeightContext';

class Index extends React.Component {
    static propTypes  = {
        children: PropTypes.node,
        animateOver: PropTypes.bool,
        indexPage: PropTypes.bool,
        width: PropTypes.number.isRequired,
    };

    static defaultProps = {
        animateOver: false,
        indexPage: false,
        children: ''
    };

    state = {
        buttonsComponentHeight: 'auto',
        buttonsComponentInnerHeight: 'auto',
        skewHeight: 0,
    };

    constructor(props) {
        super(props);
        this.buttonsComponentRef = React.createRef();
    }

    componentDidMount() {
        this.calculateSkewHeight();
    }

    componentDidUpdate(prevProps) {
        const { width } = this.props;
        if(prevProps.width !== width) {
            this.calculateSkewHeight();
        }
    }

    calculateSkewHeight = () => {
        const { width, animateOver } = this.props;
        const radians= constants.skewDegrees*Math.PI/180;
        const skewHeight = width*Math.tan(radians);
        this.setState({
            buttonsComponentHeight: animateOver === false ? this.buttonsComponentRef.current.offsetHeight + 2*Math.ceil(skewHeight/2) : 0,
            buttonsComponentInnerHeight: animateOver === false ? this.buttonsComponentRef.current.offsetHeight - 40 : 0,
            skewHeight: skewHeight,
        });
    };

    render() {
        const { children, animateOver, width, indexPage } = this.props;
        const { skewHeight, buttonsComponentHeight, buttonsComponentInnerHeight } = this.state;

        const beforeChildren = !indexPage
            ? <div className="upperLine sliderDecoratorLines" />
            : null;
        const afterChildren = !indexPage
            ? <div className="lowerLine sliderDecoratorLines"  />
            : null;


        return (
            <section id="slider" className="slider-container">
                <AnimatedDiv
                    animateOver={animateOver}
                    skewHeight={skewHeight}
                    style={{
                    }}
                >
                    <ButtonsComponentHeightContext.Provider value={{ buttonsComponentInnerHeight }}>
                        <SkewedDiv
                            beforeChildren={beforeChildren}
                            afterChildren={afterChildren}
                            style={{
                                paddingTop: animateOver ? Math.ceil(skewHeight/2) : Math.ceil(skewHeight/2),
                                paddingBottom: animateOver ? Math.ceil(skewHeight/2) : Math.ceil(skewHeight/2),
                                minHeight: animateOver ? 'auto' :buttonsComponentHeight + 'px',
                            }}
                        >
                            {children}
                        </SkewedDiv>
                    </ButtonsComponentHeightContext.Provider>
                    {/* get buttons height on details page load */}
                    {width >= constants.mdMinBreakpoint
                        ? (
                            <div
                                ref={this.buttonsComponentRef}
                                style={{
                                    visibility: 'hidden',
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%',
                                }}
                            >
                                <Buttons width={width} />
                            </div>
                        )
                        : (
                            // on mobile height of a container is set to 2*width of a screen
                            // to avoid big blank spaces when there is no data to display
                            <div
                                ref={this.buttonsComponentRef}
                                style={{
                                    visibility: 'hidden',
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%',
                                    height: 2*width,
                                }}
                            />
                        )
                    }
                </AnimatedDiv>
            </section>
        );
    }
}
export default withWidth(Index);
