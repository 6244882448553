import React from 'react';
import Button from '../button';
import SvgBudzetDomowyOszczedzanieInvestowanie
    from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgBudzetDomowyOszczedzanieInvestowanie';
import SvgWartoscPieniadzaKieszonkowe
    from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgWartoscPieniadzaKieszonkowe';
import SvgCrowdfunding from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgCrowdfunding';
import SvgBudzetObywatelskiIBudzetowanie
    from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgBudzetObywatelskiIBudzetowanie';
import SvgBudzetDomowy from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgBudzetDomowy';
import SvgCrowdfundingDlaRodzin from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgCrowdfundingDlaRodzin';
import SvgBudzetDomowyInwestowanie
    from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgBudzetDomowyInwestowanie';
import SvgZerowastewdomu from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgZeroWasteWDomu';
import SvgEdukacjaFinansowaDzieci
    from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgEdukacjaFinansowaDzieci';
import SvgOszczedzanie from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgOszczedzanie';
import SvgZeroWasteWSzkole from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgZeroWasteWSzkole';
import constants from '../../../constants/javascriptConstants';
import SvgKupStarterPack from '../../../svg/buttonsSeparate/buyStarterpackButtons/SvgKupStarterPack';
import SvgKupStarterPackCovid from '../../../svg/covid/SvgKupStarterPackCovid';

class EconomicButtons extends React.Component {
    render() {
        return (
          <>
              {/* First Row */}
              <Button
                  className="budzetDomowyOszczedzanieInvestowanie"
                  buttonType="hexButton"
                  linkLabel="Budżet domowy, oszczędzanie, inwestowanie"
                  link="/budzet-domowy/"
              >
                  <SvgBudzetDomowyOszczedzanieInvestowanie />
              </Button>
              <Button
                  className="wartoscPieniadzaKieszonkowe"
                  buttonType="hexButton"
                  linkLabel="Wartość pieniądza i kieszonkowe"
                  link="/wartosc-pieniadza/"
              >
                  <SvgWartoscPieniadzaKieszonkowe />
              </Button>
              <Button
                  className="crowdfunding"
                  buttonType="hexButton"
                  linkLabel="Crowdfunding"
                  link="/crowdfunding-szkola/"
              >
                  <SvgCrowdfunding />
              </Button>
              <Button
                  className="budzetObywatelskiIBudzetowanie"
                  buttonType="circleButton"
                  linkLabel="Budżet Obywatelski i Budżetowanie"
                  link="/budzet-obywatelski/"
              >
                  <SvgBudzetObywatelskiIBudzetowanie />
              </Button>

              {/* Second Row */}

              <Button
                  className="budzetDomowy"
                  buttonType="hexButton"
                  linkLabel=""
                  link=""
              >
                  <SvgBudzetDomowy />
              </Button>
              <Button
                  className="crowdfundingDlaRodzin"
                  buttonType="hexButton"
                  linkLabel="Crowdfunding Dla Rodzin"
                  link="/crowdfunding-rodzina/"
              >
                  <SvgCrowdfundingDlaRodzin />
              </Button>
              <Button
                  className="budzetDomowyInwestowanie"
                  buttonType="hexButton"
                  linkLabel=""
                  link=""
              >
                  <SvgBudzetDomowyInwestowanie />
              </Button>
              <Button
                  className="zeroWasteWDomu"
                  buttonType="circleButton"
                  linkLabel="Zero waste w domu"
                  link="/zerowaste-dom/"
              >
                  <SvgZerowastewdomu />
              </Button>
              <Button
                  className="edukacjaFinansowaDzieci"
                  buttonType="hexButton"
                  linkLabel="Edukacja Finansowa Dzieci"
                  link="/edukacja-finansowa-dzieci/"
              >
                  <SvgEdukacjaFinansowaDzieci />
              </Button>
              <Button
                  className="oszczedzanie"
                  buttonType="circleButton"
                  linkLabel=""
                  link=""
              >
                  <SvgOszczedzanie />
              </Button>
              <Button
                  className="zeroWasteWSzkole"
                  buttonType="circleButton"
                  linkLabel="Zero waste w szkole"
                  link="/zerowaste-szkola/"
              >
                  <SvgZeroWasteWSzkole />
              </Button>
              <Button
                  className="kupStarterPack"
                  link="/kup-starter-pack/"
                  buttonType='circleButton'
                  linkLabel="Kup Starter Pack"
                  isDisabled={constants.isCovid}
              >
                  {constants.isCovid
                      ? <SvgKupStarterPackCovid />
                      : <SvgKupStarterPack />
                  }
              </Button>
          </>
        );
    }
}

export default EconomicButtons;
