import React from 'react';

const SvgSvgNoButtonsJustGridEconomicXs = props => (
    <svg
        data-name="Warstwa 2"
        viewBox="0 0 360 1386"
        {...props}
    >
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#3d3d3d"
            opacity={0.6}
            d="M214.25 1340.75l-1.88 2.17-45.78-40.54 1.88-2.17 45.78 40.54zM197.43 254.63l-46.47 39.54-1.86-2.19 46.46-39.55 1.87 2.2z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M81.247 617.222l3.44-.054.24 15.448-3.44.054zM81.602 640.103l3.44-.053.24 15.448-3.44.054zM81.963 663.041l3.44-.053.237 15.449-3.44.052zM82.234 685.898l3.44-.053.24 15.448-3.44.053zM82.626 708.796l3.44-.053.237 15.448-3.44.053zM264.29 304.974l3.23-.053.24 14.518-3.23.053zM264.668 326.45l3.24-.054.245 14.518-3.24.055zM265.032 347.961l3.24-.054.245 14.518-3.24.054z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#3d3d3d"
            opacity={0.6}
            d="M158 367.02l52.19 31.63-1.5 2.44-52.18-31.62 1.49-2.45zM151.937 505.285l56.85-33.822 1.467 2.466-56.85 33.822zM189.53 611.12l-43.81-25.78 1.49-2.46L191 608.65l-1.47 2.47z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M198.668 931.887l14.545-5.21 1.16 3.238-14.545 5.21zM177.139 939.508l14.545-5.21 1.16 3.238-14.545 5.21zM155.554 947.341l14.545-5.21 1.16 3.238-14.544 5.21zM134.108 954.911l14.544-5.21 1.16 3.238-14.544 5.21z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#3d3d3d"
            opacity={0.6}
            d="M209.87 1160.57l-46.81 22.46-1.1-2.66 46.8-22.46 1.11 2.66zM76.69 926.77l5.61-57.73 2.86.3-5.61 57.72-2.86-.29z"
        />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M67.337 1047.575l3.43-.251 1.127 15.409-3.431.25zM69.046 1070.41l3.431-.25 1.126 15.408-3.43.25zM70.68 1093.17l3.432-.25 1.126 15.408-3.431.25zM72.269 1116.103l3.43-.25 1.127 15.408-3.431.25zM70.962 1142.127l3.44-.24 1.081 15.45-3.44.24zM72.26 1165.032l3.44-.24 1.082 15.45-3.44.24zM267.283 734.518l3.23-.259 1.161 14.52-3.23.259zM266.276 754.376l3.22-.253 1.137 14.475-3.22.253zM270.596 777.798l3.23-.258 1.16 14.523-3.229.258zM269.544 797.34l3.22-.253 1.14 14.475-3.22.253zM259.387.039l3.44-.034.154 15.449-3.44.034zM259.605 22.916l3.44-.035.153 15.45-3.44.034zM259.832 45.782l3.44-.034.154 15.45-3.44.033zM260.07 68.749l3.44-.035.154 15.45-3.44.034zM260.288 91.585l3.44-.034.154 15.45-3.44.034zM260.176 114.475l3.44-.034.153 15.45-3.44.034z"
        />
        <path
            d="M103.32 777.05a1.31 1.31 0 00-1.32-1.28h-6a1.3 1.3 0 00-1.41 1.2 1.27 1.27 0 001.12 1.4h6.37a1.4 1.4 0 001.28-1.35zM103.51 782.56a1.31 1.31 0 10-2.62-.19V790a1.3 1.3 0 001.21 1.4 1.32 1.32 0 001.41-1.21zM97.18 801.37a1.36 1.36 0 00.83-.32l5-4.16a1.32 1.32 0 00-1.31-2.3 1.43 1.43 0 00-.35.3l-5 4.16a1.3 1.3 0 00-.2 1.84 1.75 1.75 0 001 .51zM90.8 762.4a.86.86 0 00.83-.83v-4l.9-.83a.82.82 0 000-1.16.8.8 0 00-.9-.19v-1.92a.83.83 0 00-1.66 0v3.39l-.83.77a.81.81 0 000 1.14.78.78 0 00.89.2v2.49a.78.78 0 00.65.89h.12z"
            fill="#fff"
        />
        <text
            transform="translate(53.32 825.81)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'w'}
        </text>
        <text
            transform="translate(59.73 825.81)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'a'}
        </text>
        <text
            transform="translate(64.24 825.81)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'r'}
        </text>
        <text
            transform="translate(67.67 825.81)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'t'}
        </text>
        <text
            transform="translate(70.76 825.81)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'o\u015B\u0107 pieni\u0105dza'}
        </text>
        <text
            transform="translate(61.13 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'i'}
        </text>
        <text
            transform="translate(65.2 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'k'}
        </text>
        <text
            transform="translate(69.91 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'ies'}
        </text>
        <text
            transform="translate(80.46 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'z'}
        </text>
        <text
            transform="translate(84.41 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'on'}
        </text>
        <text
            transform="translate(94.33 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'k'}
        </text>
        <text
            transform="translate(98.84 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'o'}
        </text>
        <text
            transform="translate(103.69 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'w'}
        </text>
        <text
            transform="translate(110.04 836.05)"
            style={{
                isolation: 'isolate'
            }}
            fontSize={8.537}
            fill="#fff"
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
        >
            {'e'}
        </text>
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M256.228 1215.15l3.431-.25 1.126 15.409-3.43.25zM257.926 1237.956l3.431-.25 1.126 15.408-3.43.251zM259.572 1260.726l3.43-.25 1.127 15.408-3.431.25zM261.161 1283.689l3.431-.25 1.126 15.408-3.43.25zM262.945 1306.439l3.43-.251 1.127 15.409-3.431.25z"
        />
        <path fill="none" d="M260.33 1299.13l-1.13-15.41" />
        <path
            style={{
                isolation: 'isolate'
            }}
            fill="#5d5d5d"
            opacity={0.6}
            d="M252.909 1169.484l3.43-.25 1.127 15.408-3.43.251zM254.597 1192.3l3.431-.25 1.126 15.408-3.43.251zM264.285 286.597l3.23-.054.24 14.518-3.23.054z"
        />
    </svg>
);

export default SvgSvgNoButtonsJustGridEconomicXs;
