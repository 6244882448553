import React from 'react';
import PropTypes from 'prop-types';

/**
 * Żeby poprawnie działało ustawianie koloru należy ustawić atrybut `fill` tak by w odpowiednich miejscacach przyjmował wartość zmiennej `color`. to jest: `fill={color}`.
 * Jeżeli któryś z atrybutów 'fill' ma pozostać z zafiksowanym kolorem to należy wartość hexową podmienić na zmienną css, czyli fill="#FFF" powinien stać się fill="var(--white)".
 *
 * Na koniec należy zmienić nazwę pliku tak by zaczynała się od 'Svg'. np. SvgPiorunek.js
 *
 *  Jeśli ikona ma mieć rozmiar w przyjmowanych props'ach to:
 *  # należy w propTypes i defaultProps odkomentować linijkę z parametrem size i w defaultProps ustawić jakąś wartość.
 *  # znaleść tag 'svg' i fragment 'width="1em" height="1em"' podmienić na 'width={`${size}px`} height={`${size}px`}'
 *  # odkomentować 'size'  w  'const {//size, color, ...props} = this.props;'
 */

class SvgIconReturn extends React.Component {
    static propTypes = {
        height: PropTypes.number
    };

    static defaultProps = {
        height: 100
    };

    render() {
        const { height, ...props } = this.props;

        return (
            <svg
                viewBox="87.875 26.22 419.53 367.089"
                width={(419.53/367.089) * height}
                height={height}
                {...props}
            >
                <path
                    d="M94.684 155.55L238.9 31.015c12.624-10.902 32.519-2.052 32.519 14.884v65.595c131.62 1.506 235.987 27.885 235.987 152.618 0 50.345-32.434 100.22-68.283 126.295-11.188 8.138-27.131-2.075-23.006-15.266 37.154-118.822-17.623-150.367-144.697-152.196v72.037c0 16.961-19.911 25.772-32.519 14.884L94.684 185.318c-9.072-7.835-9.084-21.922 0-29.768z"
                    fill="#6d6d6d"
                />
            </svg>
        );
    }
}

export default SvgIconReturn;
