import React from 'react';

const SvgBudzetDomowyInwestowanie = props => (
    <svg
        id="budzet_domowy_inwestowanie_svg__komunikacja-i-wspolpraca"
        viewBox="0 0 159.9 138.6"
        {...props}
    >
        <path
            className="st2"
            d="M159.9 69.1l-39.8 69.3-80 .2L0 69.4 39.9.1l79.9-.1 40.1 69.1z"
        />
    </svg>
);

export default SvgBudzetDomowyInwestowanie;
