import React from 'react';

class SvgSvgNoButtonsJustGridFutureLg extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg viewBox="0 -63.387 1366 831.387" {...props}>
                <path
                    opacity={0.6}
                    fill="#3D3D3D"
                    d="M192.85 177.668l-1.103-241.044 3.062-.023 1.103 241.043zM261.134 490.738l-85.095-12.382.503-3.018 85.096 12.381zM304.475 417.524l-66.167-92.218 2.512-1.75 66.167 92.218zM416.032 634.807l-64.474-68.475 2.107-2.222 64.474 68.477zM112.042 360.34l-1.942-2.369 41.882-34.415 1.942 2.369zM506.35 336.3l-225.172-81.614 1.037-2.882 225.172 81.614zM530.56 592.662l-2.895-1.001 59.23-181.348 2.893 1.001zM964.82 421.073l-29.582-130.392 2.984-.695 29.582 130.394zM849.879 561.806l-.79-2.959 97.379-27.72.789 2.957zM1053.067 419.46l-2.87-1.063 85.78-231.76 2.87 1.062zM786.406 484.189l-2.914-.942 66.066-192.914 2.913.943zM1230.633 281.537l-22.293-94.196 2.98-.705 22.294 94.196z"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M705.213 500.783l-10.423-12.787 2.848-2.322 10.423 12.787zM689.802 481.837l-10.425-12.785 2.848-2.322 10.425 12.785zM674.413 462.889l-10.423-12.787 2.847-2.321 10.423 12.786zM658.933 444.011l-10.425-12.786 2.847-2.322 10.426 12.787zM643.44 425.01l-10.423-12.788 2.849-2.321 10.423 12.786z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="#3D3D3D"
                    d="M656.81 299.526l-1.504-2.664 148.631-83.953 1.505 2.665zM586.841-63.387h3.063V259.61h-3.063z"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M513.312 383.804l-2.052-2.948 13.23-9.21 2.052 2.948zM493.681 397.419l-2.053-2.947 13.23-9.218 2.053 2.947zM474.11 411.07l-2.053-2.947 13.228-9.22 2.053 2.947zM454.509 424.718l-2.053-2.947 13.228-9.216 2.053 2.946zM434.907 438.368l-2.053-2.946 13.229-9.218 2.053 2.947zM415.263 452.005l-2.05-2.947 13.23-9.21 2.05 2.947z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="#5D5D5D"
                    d="M888.643 53.472h3.676v14.817h-3.676zM888.649 31.519h3.677v14.817h-3.677zM888.657 9.568h3.675v14.815h-3.675z"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M1158.934 404.413l-2.182-2.954 13.271-9.802 2.182 2.954zM1139.33 418.94l-2.182-2.957 13.275-9.8 2.183 2.958zM1119.703 433.479l-2.183-2.955 13.27-9.806 2.183 2.955zM1100.046 448l-2.185-2.955 13.265-9.807 2.185 2.955zM1080.38 462.521l-2.183-2.955 13.272-9.802 2.182 2.955z"
                    />
                </g>
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M664.726 601.845l-.969-3.334 14.97-4.35.97 3.335zM642.524 608.225l-.968-3.335 14.97-4.346.969 3.335zM620.306 614.662l-.967-3.333 14.97-4.341.967 3.333zM598.17 621.09l-.966-3.333 14.969-4.34.967 3.333zM575.95 627.499l-.967-3.333 14.97-4.342.967 3.332zM553.836 633.9l-.965-3.335 14.971-4.333.965 3.335z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="#5D5D5D"
                    d="M888.643 119.724h3.676v14.816h-3.676zM888.649 97.771h3.677v14.816h-3.677zM888.657 75.818h3.675v14.817h-3.675zM888.643-11.861h3.676V2.956h-3.676zM888.649-33.814h3.677v14.817h-3.677zM888.657-55.765h3.675v14.815h-3.675z"
                />
            </svg>
        );
    }
}

export default SvgSvgNoButtonsJustGridFutureLg;
