import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getSizeFromWidth } from '../../helpers/common';
import SvgNoButtonsJustGridFutureXs from '../../svg/grid/futureGrid/SvgNoButtonsJustGridFutureXs';
import SvgNoButtonsJustGridFutureMd from '../../svg/grid/futureGrid/SvgNoButtonsJustGridFutureMd';
import SvgNoButtonsJustGridFutureLg from '../../svg/grid/futureGrid/SvgNoButtonsJustGridFutureLg';
import SvgNoButtonsJustGridEconomicLg from '../../svg/grid/economicGrid/SvgNoButtonsJustGridEconomicLg';
import SvgNoButtonsJustGridEconomicMd from '../../svg/grid/economicGrid/SvgNoButtonsJustGridEconomicMd';
import SvgNoButtonsJustGridEconomicXs from '../../svg/grid/economicGrid/SvgNoButtonsJustGridEconomicXs';
import './Buttons.scss';
import './specificButtons.scss';
import constants from '../../constants/javascriptConstants';
import { Link } from '@reach/router';
import SvgSvgNoButtonsJustGridMainLg from '../../svg/grid/mainPageGrid/SvgNoButtonsJustGridMainLg';
import SvgSvgNoButtonsJustGridMainMd from '../../svg/grid/mainPageGrid/SvgNoButtonsJustGridMainMd';
import SvgSvgNoButtonsJustGridMainXs from '../../svg/grid/mainPageGrid/SvgNoButtonsJustGridMainXs';
import SvgIconReturn from '../../svg/icons/SvgIconReturn';
import FutureButtons from './ButtonsPages/FutureButtons';
import EconomicButtons from './ButtonsPages/EconomicButtons';
import MainButtons from './ButtonsPages/MainButtons';
import SvgNoButtonsJustGridMainLgCovid from '../../svg/covid/SvgNoButtonsJustGridMainLgCovid';
import SvgNoButtonsJustGridMainMdCovid from '../../svg/covid/SvgNoButtonsJustGridMainMdCovid';
import SvgNoButtonsJustGridMainXsCovid from '../../svg/covid/SvgNoButtonsJustGridMainXsCovid';
import FutureWorkButtons from './ButtonsPages/FutureWorkButton';


export default class extends Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        competencesType: PropTypes.string
    };
    static defaultProps = {
        competencesType: ''
    };

    // state = {};

    render() {
        const { width, competencesType, ...otherProps } = this.props;
        let svg;
        switch (getSizeFromWidth(width)) {
            case 'xl':
                switch (competencesType) {
                    case constants.competencesTypes.future:
                        svg = <SvgNoButtonsJustGridFutureLg />;
                        break;
                    case constants.competencesTypes.economic:
                        svg = <SvgNoButtonsJustGridEconomicLg />;
                        break;
                    case constants.competencesTypes.futureWork:
                        svg = <SvgNoButtonsJustGridEconomicLg />;
                        break;
                    default:
                        svg = constants.isCovid
                            ? <SvgNoButtonsJustGridMainLgCovid />
                            : <SvgSvgNoButtonsJustGridMainLg />;
                        break;
                }
                break;
            case 'lg':
                switch (competencesType) {
                    case constants.competencesTypes.future:
                        svg = <SvgNoButtonsJustGridFutureLg />;
                        break;
                    case constants.competencesTypes.economic:
                        svg = <SvgNoButtonsJustGridEconomicLg />;
                        break;
                    case constants.competencesTypes.futureWork:
                        svg = <SvgNoButtonsJustGridEconomicLg />;
                        break;
                    default:
                        svg = constants.isCovid
                            ? <SvgNoButtonsJustGridMainLgCovid />
                            : <SvgSvgNoButtonsJustGridMainLg />;
                        break;
                }
                break;
            case 'md':
                switch (competencesType) {
                    case constants.competencesTypes.future:
                        svg = <SvgNoButtonsJustGridFutureMd />;
                        break;
                    case constants.competencesTypes.economic:
                        svg = <SvgNoButtonsJustGridEconomicMd />;
                        break;
                    case constants.competencesTypes.futureWork:
                        svg = <SvgNoButtonsJustGridEconomicMd />;
                        break;
                    default:
                        svg = constants.isCovid
                            ? <SvgNoButtonsJustGridMainMdCovid />
                            : <SvgSvgNoButtonsJustGridMainMd />;
                        break;
                }
                break;
            // case 'sm':
            //     svg = <SvgButtonsSm />;
            //     break;
            case 'xs':
                switch (competencesType) {
                    case constants.competencesTypes.future:
                        svg = <SvgNoButtonsJustGridFutureXs />;
                        break;
                    case constants.competencesTypes.economic:
                        svg = <SvgNoButtonsJustGridEconomicXs />;
                        break;
                    case constants.competencesTypes.futureWork:
                        svg = <SvgNoButtonsJustGridEconomicXs />;
                        break;
                    default:
                        svg = constants.isCovid
                            ? <SvgNoButtonsJustGridMainXsCovid />
                            : <SvgSvgNoButtonsJustGridMainXs />;
                        break;
                }
                break;
            case 'none':
            default:
                svg = null;
                break;
        }
        switch (competencesType) {
            case constants.competencesTypes.future:
                return (
                    <div id="buttonsContainer" {...otherProps}>
                        <div id="buttonsInnerContainer" >
                            {svg}
                            <Link to="" className="returnButton">
                                <SvgIconReturn height={50} />
                            </Link>
                            <FutureButtons />
                        </div>
                    </div>
                );
            case constants.competencesTypes.economic:
                return (
                    <div id="buttonsContainer" {...otherProps}>
                        <div id="buttonsInnerContainer" >
                            {svg}
                            <Link to="/" className="returnButton">
                                <SvgIconReturn height={50} />
                            </Link>
                            <EconomicButtons />
                        </div>
                    </div>
                );
            case constants.competencesTypes.futureWork:
                return (
                    <div id="buttonsContainer" {...otherProps}>
                        <div id="buttonsInnerContainer" >
                            {svg}
                            <Link to="/" className="returnButton">
                                <SvgIconReturn height={50} />
                            </Link>
                            <FutureWorkButtons />
                        </div>
                    </div>
                );
            default:
                return (
                    <div id="buttonsContainer" {...otherProps}>
                        {svg}
                        <MainButtons />
                    </div>
                );
        }
    }
}
