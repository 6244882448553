import React from 'react';

const SvgBudzetDomowy = props => (
    <svg
        id="budzet_domowy_svg__ocena-i-podejmowanie-decyzji"
        viewBox="0 0 170.04 147.45"
        {...props}
    >
        <path
            className="st0"
            d="M165.44 73.56l-40.07 69.72-80.41.16L4.62 73.89 44.68 4.17 125.09 4l40.35 69.56z"
        />
        <path
            className="st3"
            d="M42.66 147.45L0 73.89 42.37.17l85-.17L170 73.56l-42.37 73.72zM9.24 73.88l38 65.56 75.8-.15 37.76-65.72L122.79 8 47 8.16z"
        />
        <path fill="none" d="M25.09 84.2h117.88v21.27H25.09z" />
    </svg>
);

export default SvgBudzetDomowy;
