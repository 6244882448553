import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { withWidth } from '../withWidth';
import constants from '../../constants/javascriptConstants';

const PosedDiv = posed.div({});
class AnimatedDiv extends React.Component {
    static propTypes  = {
        width: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,
        animateOver: PropTypes.bool.isRequired,
    };

    render() {
        const { animateOver, children, width, ...otherProps } = this.props;
        const animateOverAnimationConfig = {
            enter: {
                x: 0,
                y: 0,
                opacity: 1,
                //transition: { duration: 800, ease: 'easeInOut' }
                transition: { type: 'spring', stiffness: 50, damping: 20 }
            },
            exit: {
                x: '-100%',
                y: Math.floor(width*Math.tan(constants.skewDegrees*Math.PI/180)),
                opacity: 1,
                //transition: { duration: 800, ease: 'easeInOut' }
                transition: { type: 'spring', stiffness: 50, damping: 20 }
            },
        };
        const animationConfig = {
            enter: {
                x: 0,
                y: 0,
                opacity: 1,
                //transition: { duration: 800, ease: 'easeInOut' }
                transition: { type: 'spring', stiffness: 50, damping: 20 }
            },
            exit: {
                x: '100%',
                y: Math.floor(-width*Math.tan(constants.skewDegrees*Math.PI/180)),
                opacity: 1,
                //transition: { duration: 800, ease: 'easeInOut' }
                transition: { type: 'spring', stiffness: 50, damping: 20 }
            },
        };

        return (
            <PosedDiv
                poseConfig={animateOver ? animateOverAnimationConfig : animationConfig}
                {...otherProps}
            >
                {children}
            </PosedDiv>
        );
    }
}

export default withWidth(AnimatedDiv);
