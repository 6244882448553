import React from 'react';

class SvgSvgNoButtonsJustGridFutureMd extends React.Component {
    render() {
        const { ...props } = this.props;

        return (
            <svg
                viewBox="0 -127.583 768.85 1151.17"
                {...props}
            >
                <path
                    opacity={0.6}
                    fill="none"
                    stroke="#3D3D3D"
                    strokeWidth={3}
                    strokeMiterlimit={10}
                    d="M304.53 769.639L245 840.999M267 888.499h203.756"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M242.673 179.166l-2.154-1.912 8.59-9.676 2.155 1.913zM229.96 193.5l-2.155-1.912 8.592-9.675 2.154 1.913zM217.222 207.833l-2.156-1.913 8.59-9.678 2.155 1.913zM204.502 222.142l-2.156-1.913 8.59-9.68 2.155 1.914zM191.753 236.498l-2.157-1.915 8.593-9.677 2.157 1.915z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="#5D5D5D"
                    d="M797.428-52.655h3.18v12.814h-3.18zM797.434-71.639h3.182v12.814h-3.182zM797.442-90.622h3.178v12.813h-3.178z"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M1031.224 250.884l-1.884-2.555 11.48-8.466 1.885 2.555zM1014.298 263.462l-1.886-2.556 11.479-8.474 1.887 2.556zM997.24 275.955l-1.888-2.555 11.473-8.479 1.888 2.556zM980.239 288.512l-1.89-2.555 11.474-8.482 1.888 2.555zM963.233 301.069l-1.887-2.555 11.477-8.477 1.887 2.555z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="#5D5D5D"
                    d="M500.989 415.466l-1.37-2.674 11.998-6.143 1.37 2.674zM483.217 424.619l-1.367-2.673 12.001-6.139 1.367 2.673zM465.437 433.625l-1.368-2.67 12-6.144 1.367 2.67zM447.661 442.804l-1.365-2.671 12-6.134 1.366 2.671zM797.428 4.635h3.18v12.813h-3.18zM797.434-14.348h3.182v12.813h-3.182zM797.442-33.332h3.178v12.813h-3.178z"
                />
                <path
                    opacity={0.6}
                    fill="none"
                    stroke="#3D3D3D"
                    strokeWidth={3}
                    strokeMiterlimit={10}
                    d="M189.599 323.923l100.667 85.558M402.375 133l107.292 39.625M313.333-127.583V53M594.534-127.583v223.25"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M601.381 260.897l-2.478-.048.236-11.136 2.479.049zM601.032 277.395l-2.477-.047.235-11.137 2.478.048zM600.682 293.892l-2.476-.047.234-11.137 2.478.049zM600.333 310.389l-2.479-.048.236-11.136 2.479.049zM599.985 326.888l-2.481-.048.239-11.137 2.476.049z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="none"
                    stroke="#3D3D3D"
                    strokeWidth={3}
                    strokeMiterlimit={10}
                    d="M317.949 206.198l38.718 160.503M603.782 482.659l14.789 69.34M435 690.701l106.512-28.156"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M360.395 534.945l-2.87.274-1.228-12.882 2.87-.274zM362.214 554.03l-2.868.273-1.228-12.881 2.868-.274zM364.035 573.113l-2.87.274-1.231-12.883 2.869-.274zM365.862 592.19l-2.87.274-1.231-12.882 2.87-.275zM367.725 611.252l-2.871.274-1.231-12.883 2.871-.274zM369.72 630.362l-2.87.274-1.231-12.881 2.869-.275zM371.595 649.417l-2.87.274-1.232-12.883 2.871-.274z"
                    />
                </g>
                <path
                    opacity={0.6}
                    fill="none"
                    stroke="#3D3D3D"
                    strokeWidth={3}
                    strokeMiterlimit={10}
                    d="M219.88 542.138l73.472-58.488"
                />
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M163.503 693.029l-2.87.273-1.227-12.881 2.869-.274zM165.321 712.114l-2.868.273-1.228-12.881 2.868-.274zM167.143 731.195l-2.87.275-1.231-12.883 2.869-.274zM168.973 750.276l-2.87.274-1.231-12.882 2.869-.274zM170.845 769.352l-2.87.274-1.231-12.883 2.87-.274zM172.828 788.446l-2.869.274-1.232-12.882 2.87-.274zM174.715 807.517l-2.87.275-1.232-12.883 2.872-.275z"
                    />
                </g>
                <g fill="#5D5D5D">
                    <path
                        opacity={0.6}
                        d="M591.395 737.167l-2.578-1.288 5.783-11.575 2.578 1.288zM582.88 754.34l-2.579-1.29 5.79-11.572 2.578 1.29zM574.302 771.485l-2.579-1.288 5.783-11.577 2.578 1.288zM565.714 788.671l-2.577-1.288 5.786-11.573 2.578 1.289zM557.081 805.737l-2.58-1.289 5.783-11.576 2.58 1.289z"
                    />
                </g>
            </svg>
        );
    }
}

export default SvgSvgNoButtonsJustGridFutureMd;
