import React from 'react';

const SvgOszczedzanie = props => (
    <svg
        id="oszczedzanie_svg__komunikacja-i-wspolpraca"
        viewBox="0 0 160.82 139.44"
        {...props}
    >
        <defs>
            <style>
                {
                    '.oszczedzanie_svg__cls-2{fill:none}'
                }
            </style>
        </defs>
        <path
            className="st1"
            d="M160.82 69.56l-40.06 69.72-80.41.16L0 69.88 40.06.16 120.48 0l40.34 69.56z"
        />
        <path
            className="st1"
            d="M145.7 69.59l-32.53 56.61-65.29.13-32.76-56.48 32.53-56.61 65.29-.13 32.76 56.48z"
        />
        <path
            className="st2"
            d="M45.48 130.49L10.31 69.86 45.24 9.08l70.1-.14 35.17 60.64-34.93 60.77zM19.92 69.84l30.35 52.32 60.49-.12L140.9 69.6l-30.35-52.33-60.49.12z"
        />
    </svg>
);

export default SvgOszczedzanie;
