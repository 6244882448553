import React from 'react';

const SvgNoButtonsJustGridMainXs = props => (
    <svg
        id="noButtonsJustGridMainXs_svg__Warstwa_1"
        data-name="Warstwa 1"
        viewBox="0 0 347.86 1288.78"
        {...props}
    >
        <defs>
            <style>
                {
                    '.noButtonsJustGridMainXs_svg__cls-1{fill:#fff}.noButtonsJustGridMainXs_svg__cls-2{fill:#b3b3b2}.noButtonsJustGridMainXs_svg__cls-5,.noButtonsJustGridMainXs_svg__cls-6,.noButtonsJustGridMainXs_svg__cls-7{fill:none;stroke-miterlimit:10;opacity:.6;isolation:isolate}.noButtonsJustGridMainXs_svg__cls-5{stroke:#9f9f9f}.noButtonsJustGridMainXs_svg__cls-4{fill:#9f9f9f}.noButtonsJustGridMainXs_svg__cls-5{stroke-width:6.01px}.noButtonsJustGridMainXs_svg__cls-6,.noButtonsJustGridMainXs_svg__cls-7{stroke:#3d3d3d;stroke-width:3.2px}.noButtonsJustGridMainXs_svg__cls-7{stroke-dasharray:11.22 6.41}'
                }
            </style>
        </defs>
        <path
            className="noButtonsJustGridMainXs_svg__cls-1"
            d="M106.92 412.21l-23.07 40.13-46.3.08-23.23-40.01 23.07-40.17 46.3-.08 23.23 40.05z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-2"
            d="M36.23 454.74l-24.55-42.33L36.07 370l48.93-.13 24.55 42.34-24.38 42.45zM17 412.41l21.86 37.72 43.66-.08 21.75-37.84-21.9-37.77-43.62.08z"
        />
        <path
            d="M65.36 1224.46a43.11 43.11 0 10-6.43-60.63 43.12 43.12 0 006.43 60.63z"
            style={{
                isolation: 'isolate'
            }}
            strokeWidth={6.49}
            stroke="#9f9f9f"
            fill="none"
            strokeMiterlimit={10}
            opacity={0.6}
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-4"
            d="M342.54 527.41l-23.07 40.13-46.3.08-23.23-40.05 23.07-40.13 46.3-.08 23.23 40.05z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-1"
            d="M276.13 562.49l-20.26-34.92 20.1-35 40.37-.08 20.27 34.92-20.11 35zm-14.69-34.92l17.46 30.12 34.84-.08 17.34-30.2-17.46-30.12-34.84.08zM226.6 1032.28l-61.73-27.24-7.27-67.09 54.41-39.85 61.79 27.23 7.26 67.09-54.46 39.86z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-2"
            d="M154 936.39l57.57-42.13 65.3 28.8 7.71 70.94-57.58 42.11-65.3-28.8zm58.39-34.46l-51.31 37.52 6.86 63.26 58.22 25.68 51.36-37.53-6.85-63.21z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-4"
            d="M137.75 861.72l-29.46 51.25-59.12.1-29.66-51.14 29.46-51.25 59.11-.1 29.67 51.14z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-1"
            d="M53 906.52l-25.92-44.59 25.67-44.7 51.55-.1 25.88 44.59-25.68 44.7zm-18.82-44.59l22.3 38.46 44.5-.11 22.14-38.56-22.3-38.46-44.49.11z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-4"
            d="M105.08 108.01l9.33 34.53-25.26 25.34-34.57-9.19-9.33-34.53 25.26-25.35 34.57 9.2z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-1"
            d="M88 163.46l-30.16-8-8.14-30.11 22-22.1 30.16 8 8.14 30.11zm-27.14-11.05l26 6.92 19-19.09-7-26-26-6.92-19 19.08z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-5"
            d="M308.42 344.7a11.17 11.17 0 10-11.17-11.18 11.17 11.17 0 0011.17 11.18zM105.16 567.62A11.17 11.17 0 1094 556.44a11.17 11.17 0 0011.16 11.18zM284.58 828.3a11.17 11.17 0 10-11.17-11.18 11.17 11.17 0 0011.17 11.18z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-6"
            d="M177.11 7.86v174.82"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-7"
            d="M129.51 891.71l48.68 20.42"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-6"
            d="M191 792.44l19.74 95.32"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-7"
            d="M191 1027.89l-67.59 116.27"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-6"
            d="M103.25 433.48l146.67 67.33"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-5"
            d="M255.72 1094.5a11.17 11.17 0 10-1.67-15.71 11.17 11.17 0 001.67 15.71z"
        />
        <path
            className="noButtonsJustGridMainXs_svg__cls-7"
            d="M60.6 460l36.05 77.07"
        />
    </svg>
);

export default SvgNoButtonsJustGridMainXs;
