import React from 'react';

const SvgWartoscPieniadzaKieszonkowe = props => (
    <svg
        id="wartosc_pieniadza_kieszonkowe_svg__komunikacja-i-wspolpraca"
        viewBox="0 0 160.82 139.44"
        {...props}
    >
        <defs>
            <style>
                {
                    '.wartosc_pieniadza_kieszonkowe_svg__cls-2,.wartosc_pieniadza_kieszonkowe_svg__cls-3,.wartosc_pieniadza_kieszonkowe_svg__cls-4{fill:#fff}.wartosc_pieniadza_kieszonkowe_svg__cls-3,.wartosc_pieniadza_kieszonkowe_svg__cls-4{isolation:isolate;font-size:9.28px;font-family:MyriadPro-Bold,Myriad Pro;font-weight:700}.wartosc_pieniadza_kieszonkowe_svg__cls-3{letter-spacing:0}'
                }
            </style>
        </defs>
        <path
            className="st1"
            d="M160.82 69.56l-40.07 69.71-80.41.17L0 69.88 40.06.16 120.48 0l40.34 69.56z"
        />
        <path
            className="wartosc_pieniadza_kieszonkowe_svg__cls-2"
            d="M102 47.85a1.43 1.43 0 00-1.43 1.42v21.2c0 5.44-8.31 13.33-21.34 13.33-12.45 0-20.7-9.49-20.7-13.33v-21.2a1.43 1.43 0 00-2.85 0v21.2c0 5.69 9.71 16.19 23.55 16.19 15 0 24.2-9.43 24.2-16.19v-21.2a1.43 1.43 0 00-1.43-1.42zM57.11 44H102a1.43 1.43 0 100-2.86H57.11a1.43 1.43 0 100 2.86z"
        />
        <path
            className="wartosc_pieniadza_kieszonkowe_svg__cls-2"
            d="M71.54 50a1.43 1.43 0 00-1.43-1.42h-6.62a1.43 1.43 0 00-.24 2.85h6.86A1.43 1.43 0 0071.54 50zM82.55 48.62h-6.62a1.43 1.43 0 000 2.85h6.62a1.43 1.43 0 00.24-2.85zM96.1 50a1.43 1.43 0 00-1.43-1.42h-6.61a1.43 1.43 0 00-.24 2.85h6.85A1.43 1.43 0 0096.1 50zM70.4 38.61a1.43 1.43 0 001.11-2.32A10 10 0 1187.22 36a1.43 1.43 0 102.1 1.94 1.08 1.08 0 00.18-.24 12.85 12.85 0 10-20.19.38 1.45 1.45 0 001.09.53zM63.49 65.52a1.43 1.43 0 001.43-1.43V56a1.43 1.43 0 10-2.86 0v8.08a1.43 1.43 0 001.42 1.44zM62.65 71.62l5.59 4a1.43 1.43 0 101.68-2.32l-5.61-4a1.43 1.43 0 00-1.67 2.31zM96.27 56a1.43 1.43 0 00-2.85-.24 1 1 0 000 .24v8.08a1.43 1.43 0 002.85.24 1 1 0 000-.24zM89.42 76.41a1.45 1.45 0 00.91-.33l5.43-4.51a1.43 1.43 0 00-1.83-2.2l-5.42 4.51a1.42 1.42 0 00-.18 2 1.48 1.48 0 001.09.52zM82.55 76.27h-6.62a1.43 1.43 0 000 2.85h6.62a1.43 1.43 0 00.24-2.85z"
        />
        <path
            className="wartosc_pieniadza_kieszonkowe_svg__cls-2"
            d="M82.49 34.09a.87.87 0 00.89-.87v-4.3l1-.92a.89.89 0 000-1.26.87.87 0 00-1-.19v-2.09a.91.91 0 00-1-.8.89.89 0 00-.8.8v3.68l-.88.86a.89.89 0 000 1.26.88.88 0 00.94.24v2.7a.87.87 0 00.87.89z"
        />
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-3"
            transform="translate(41.72 103.01)"
        >
            {'w'}
        </text>
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-4"
            transform="translate(48.69 103.01)"
        >
            {'a'}
        </text>
        <text
            transform="translate(53.59 103.01)"
            style={{
                isolation: 'isolate'
            }}
            letterSpacing=".02em"
            fontSize={9.28}
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
            fill="#fff"
        >
            {'r'}
        </text>
        <text
            transform="translate(57.32 103.01)"
            style={{
                isolation: 'isolate'
            }}
            letterSpacing={0}
            fontSize={9.28}
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
            fill="#fff"
        >
            {'t'}
        </text>
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-4"
            transform="translate(60.68 103.01)"
        >
            {'o\u015B\u0107 pieni\u0105dza'}
        </text>
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-4"
            transform="translate(50.21 114.14)"
        >
            {'i'}
        </text>
        <text
            transform="translate(54.63 114.14)"
            style={{
                isolation: 'isolate'
            }}
            letterSpacing=".01em"
            fontSize={9.28}
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
            fill="#fff"
        >
            {'k'}
        </text>
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-4"
            transform="translate(59.75 114.14)"
        >
            {'ies'}
        </text>
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-3"
            transform="translate(71.22 114.14)"
        >
            {'z'}
        </text>
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-4"
            transform="translate(75.51 114.14)"
        >
            {'on'}
        </text>
        <text
            transform="translate(86.3 114.14)"
            style={{
                isolation: 'isolate'
            }}
            letterSpacing="-.01em"
            fontSize={9.28}
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
            fill="#fff"
        >
            {'k'}
        </text>
        <text
            transform="translate(91.2 114.14)"
            style={{
                isolation: 'isolate'
            }}
            letterSpacing="-.01em"
            fontSize={9.28}
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
            fill="#fff"
        >
            {'o'}
        </text>
        <text
            transform="translate(96.47 114.14)"
            style={{
                isolation: 'isolate'
            }}
            letterSpacing="-.01em"
            fontSize={9.28}
            fontFamily="MyriadPro-Bold,Myriad Pro"
            fontWeight={700}
            fill="#fff"
        >
            {'w'}
        </text>
        <text
            className="wartosc_pieniadza_kieszonkowe_svg__cls-4"
            transform="translate(103.37 114.14)"
        >
            {'e'}
        </text>
        <path
            className="wartosc_pieniadza_kieszonkowe_svg__cls-2"
            d="M74.89 33.54v.28l.09.08h4a.9.9 0 00.19-1.79h-1.76L80 28.6l.06-.11a.14.14 0 010-.09v-.08m0-.05a.24.24 0 000-.12v-.08a.21.21 0 000-.11v-.31a.22.22 0 000-.08v-.06l-.09-.07a.24.24 0 00-.11-.06H75.7a.89.89 0 100 1.78h1.87L75 32.71a.41.41 0 00-.07.11v.09a.11.11 0 000 .08.24.24 0 000 .12v.17"
        />
    </svg>
);

export default SvgWartoscPieniadzaKieszonkowe;
