import React from 'react';
import Button from '../button';
import SvgMyslenieKrytyczne from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgMyslenieKrytyczne';
import SvgKomunikacjaOrazWspolpraca
    from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgKomunikacjaOrazWspolpraca';
import SvgOcenaIPodejmowanieDecyzji
    from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgOcenaIPodejmowanieDecyzji';
import SvgInteligencjaEmocjonalna from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgInteligencjaEmocjonalna';
import SvgKompleksoweRozwiazywanieProblemow
    from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgKompleksoweRozwiazywanieProblemow';
import SvgKreatywnosc from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgKreatywnosc';
import SvgZarzadzanieZespolem from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgZarzadzanieZespolem';
import SvgNegocjacje from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgNegocjacje';
import SvgZorientowanieNaProdukt from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgZorientowanieNaProdukt';
import SvgElastycznoscPoznawcza from '../../../svg/buttonsSeparate/futureSeparateButtons/SvgElastycznoscPoznawcza';
import SvgKupStarterPack from '../../../svg/buttonsSeparate/buyStarterpackButtons/SvgKupStarterPack';
import SvgKupStarterPackCovid from '../../../svg/covid/SvgKupStarterPackCovid';
import constants from '../../../constants/javascriptConstants';

class FutureButtons extends React.Component {
    render() {
        return (
            <>
                {/* First Row */}
                <Button
                    className="myslenieKrytyczne"
                    buttonType="hexButton"
                    linkLabel="Myślenie krytyczne"
                    link="/myslenie-krytyczne/"
                >
                    <SvgMyslenieKrytyczne />
                </Button>
                <Button
                    className="komunikacja"
                    buttonType="hexButton"
                    linkLabel="Komunikacja i współpraca"
                    link="/komunikacja-oraz-wspolpraca/"
                >
                    <SvgKomunikacjaOrazWspolpraca />
                </Button>
                <Button
                    className="podejmowanieDecyzji"
                    buttonType="hexButton"
                    linkLabel="Ocena i podejmowanie decyzji"
                    link="/ocena-i-podejmowanie-decyzji/"
                >
                    <SvgOcenaIPodejmowanieDecyzji />
                </Button>
                <Button
                    className="inteligencjaEmocjonalna"
                    buttonType="circleButton"
                    linkLabel="Inteligencja emocjonalna"
                    link="/inteligencja-emocjonalna/"
                >
                    <SvgInteligencjaEmocjonalna />
                </Button>

                {/* Second Row */}

                <Button
                    className="rozwiazywanieProblemow"
                    buttonType="hexButton"
                    linkLabel="Kompleksowe rozwiązywanie problemów"
                    link="/kompleksowe-rozwiazywanie-problemow/"
                >
                    <SvgKompleksoweRozwiazywanieProblemow />
                </Button>
                <Button
                    className="kreatywnosc"
                    buttonType="hexButton"
                    linkLabel="Kreatywność"
                    link="/kreatywnosc/"
                >
                    <SvgKreatywnosc />
                </Button>
                <Button
                    className="zarzadzanieZespolem"
                    buttonType="hexButton"
                    linkLabel="Zarządzanie zespołem"
                    link="/zarzadzanie-zespolem/"
                >
                    <SvgZarzadzanieZespolem />
                </Button>
                <Button
                    className="negocjacje"
                    buttonType="circleButton"
                    linkLabel="Negocjacje/mediacje"
                    link="/negocjacje/"
                >
                    <SvgNegocjacje />
                </Button>
                <Button
                    className="zorientowanieNaProdukt"
                    buttonType="hexButton"
                    linkLabel="Zorientowanie na produkt/usługę"
                    link="/zorientowanie-na-produkt/"
                >
                    <SvgZorientowanieNaProdukt />
                </Button>
                <Button
                    className="elastycznoscPoznawcza"
                    buttonType="circleButton"
                    linkLabel="Myślenie zwinne/elastyczne"
                    link="/elastycznosc-poznawcza/"
                >
                    <SvgElastycznoscPoznawcza />
                </Button>
                <Button
                    className="kupStarterPack"
                    link="/kup-starter-pack/"
                    buttonType='circleButton'
                    linkLabel="Kup Starter Pack"
                    isDisabled={constants.isCovid}
                >
                    {constants.isCovid
                        ? <SvgKupStarterPackCovid />
                        : <SvgKupStarterPack />
                    }
                </Button>
            </>
        );
    }
}

export default FutureButtons;
