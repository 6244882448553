import React from 'react';
import Button from '../button';
import SvgBudzetDomowy from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgBudzetDomowy';
import SvgBudzetDomowyInwestowanie
    from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgBudzetDomowyInwestowanie';
import SvgOszczedzanie from '../../../svg/buttonsSeparate/economicSeparateButtons/SvgOszczedzanie';
import SvgEfektywnaPracaZdalna from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgEfektywnaPracaZdalna';
import SvgRozmowaRekrutacyjna from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgRozmowaRekrutacyjna';
import SvgWizerunekWSieci from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgWizerunekWSieci';
import SvgSpolecznyAspektBiznesu
    from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgSpolecznyAspektBiznesu';
import SvgSilneStronyOsobowosci from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgSilneStronyOsobowosci';
import SvgPriorytetyOsobiste from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgPriorytetyOsobiste';
import SvgRodzajeDzialalnosci from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgRodzajeDzialalnosci';
import SvgStrategiaBudowaniaKariery
    from '../../../svg/buttonsSeparate/futureWorkSeparateButtons/SvgStrategiaBudowaniaKariery';
import constants from '../../../constants/javascriptConstants';
import SvgKupStarterPack from '../../../svg/buttonsSeparate/buyStarterpackButtons/SvgKupStarterPack';
import SvgKupStarterPackCovid from '../../../svg/covid/SvgKupStarterPackCovid';


class FutureWorkButtons extends React.Component {
    render() {
        return (
            <>
                {/* First Row */}
                <Button
                    className="efektywnaPracaZdalna"
                    buttonType="hexButton"
                    linkLabel="Efektywna Praca Zdalna"
                    link="/efektywna-praca-zdalna/"
                >
                    <SvgEfektywnaPracaZdalna />
                </Button>
                <Button
                    className="rozmowaRekrutacyjna"
                    buttonType="hexButton"
                    linkLabel="Rozmowa Rekrutacyjna"
                    link="/rozmowa-rekrutacyjna/"
                >
                    <SvgRozmowaRekrutacyjna />
                </Button>
                <Button
                    className="silneStronyOsobowosci"
                    buttonType="hexButton"
                    linkLabel="Silne Strony Osobowości"
                    link="/silne-strony-osobowosci/"
                >
                    <SvgSilneStronyOsobowosci />
                </Button>
                <Button
                    className="priorytetyOsobiste"
                    buttonType="circleButton"
                    linkLabel="Priorytety Osobiste"
                    link="/priorytety-osobiste/"
                >
                    <SvgPriorytetyOsobiste />
                </Button>

                {/* Second Row */}

                <Button
                    className="budzetDomowy"
                    buttonType="hexButton"
                    linkLabel=""
                    link=""
                >
                    <SvgBudzetDomowy />
                </Button>
                <Button
                    className="wizerunekWSieci"
                    buttonType="hexButton"
                    linkLabel="Wizerunek w Sieci"
                    link="/wizerunek-w-sieci/"
                >
                    <SvgWizerunekWSieci />
                </Button>
                <Button
                    className="budzetDomowyInwestowanie"
                    buttonType="hexButton"
                    linkLabel=""
                    link=""
                >
                    <SvgBudzetDomowyInwestowanie />
                </Button>
                <Button
                    className="rodzajeDzialalnosci"
                    buttonType="hexButton"
                    linkLabel="Rodzaje Działalności"
                    link="/rodzaje-dzialalnosci/"
                >
                    <SvgRodzajeDzialalnosci />
                </Button>
                <Button
                    className="spolecznyAspektBiznesu"
                    buttonType="hexButton"
                    linkLabel="Społeczny Aspekt Biznesu"
                    link="/spoleczny-aspekt-biznesu/"
                >
                    <SvgSpolecznyAspektBiznesu />
                </Button>
                <Button
                    className="oszczedzanie"
                    buttonType="circleButton"
                    linkLabel=""
                    link=""
                >
                    <SvgOszczedzanie />
                </Button>
                <Button
                    className="strategiaBudowaniaKariery"
                    buttonType="hexButton"
                    linkLabel="Strategia Budowania Kariery"
                    link="/strategia-budowania-kariery/"
                >
                    <SvgStrategiaBudowaniaKariery />
                </Button>
                <Button
                    className="kupStarterPack"
                    link="/kup-starter-pack/"
                    buttonType='circleButton'
                    linkLabel="Kup Starter Pack"
                    isDisabled={constants.isCovid}
                >
                    {constants.isCovid
                        ? <SvgKupStarterPackCovid />
                        : <SvgKupStarterPack />
                    }
                </Button>
            </>
        );
    }
}

export default FutureWorkButtons;
